import dayjs from 'dayjs';
import i18next from 'i18next';

export const ROLES = {
  ADMIN: 'ADMIN',
  MALL_MANAGER: 'MALL_MANAGER',
  MALL_OWNER: 'MALL_OWNER',
  STORE_MANAGER: 'STORE_MANAGER',
};

export const roles = {
  [ROLES.ADMIN]: 'Admin',
  [ROLES.MALL_MANAGER]: 'Mall manager',
  [ROLES.MALL_OWNER]: 'Mall owner',
  [ROLES.STORE_MANAGER]: 'Store manager',
};

export const getTranslatedRoles = () => ({
  [ROLES.ADMIN]: i18next.t(`adminTable.${ROLES.ADMIN.toLowerCase()}`),
  [ROLES.MALL_MANAGER]: i18next.t(
    `adminTable.${ROLES.MALL_MANAGER.toLowerCase()}`,
  ),
  [ROLES.MALL_OWNER]: i18next.t(`adminTable.${ROLES.MALL_OWNER.toLowerCase()}`),
  [ROLES.STORE_MANAGER]: i18next.t(
    `adminTable.${ROLES.STORE_MANAGER.toLowerCase()}`,
  ),
});

export const formatUsers = (users = [], searchText = '', page) => {
  const search = searchText.toLowerCase();
  return users
    .filter((user) => {
      const name = (user.name + ' ' + user.surname).toLowerCase();
      const phone = user.phoneNumber.toLowerCase();
      const email = user.email.toLowerCase();
      const role = user.roles
        .map((role) => roles[role])
        .join(', ')
        .toLowerCase();
      return [name, phone, email, role].find((str) => str.indexOf(search) > -1);
    })
    .map((user, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: user.id,
      name: user.name + ' ' + user.surname,
      phone: user.phoneNumber || '-',
      email: user.email || '-',
      role: user.roles[0]?.toLowerCase() || '',
    }));
};

export const formatStores = (stores, searchText, page) => {
  const search = searchText.toLowerCase();
  return stores
    .filter((store) => {
      const name = store.name.toLowerCase();
      const mall = store?.mall?.name.toLowerCase();
      const managers = (
        store?.storeManager?.name +
        ' ' +
        store?.storeManager?.surname
      ).toLowerCase();
      return [name, mall, managers].find((str) => str.indexOf(search) > -1);
    })
    .map((store, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: store.id,
      name: store.name,
      mall: store?.mall?.name || '-',
      storeManager: store?.storeManager
        ? store?.storeManager?.name + ' ' + store?.storeManager?.surname
        : '',
      storeManagerId: store?.storeManager?.id,
      tax: store.tax,
      closed:
        (store.endDate
          ? dayjs(store.endDate).add(1, 'day').isBefore(dayjs())
          : false) ||
        (store.startDate ? dayjs(store.startDate).isAfter(dayjs()) : false),
      users: store.user,
    }));
};

export const formatMalls = (malls, searchText, page = 1) => {
  const search = searchText.toLowerCase();
  return malls
    .filter((mall) => {
      const name = mall.name.toLowerCase();
      const managers = (
        mall.mallMangers
          .map(({ name, surname }) => `${name} ${surname}`)
          .join(', ') || '-'
      ).toLowerCase();

      const owners = (
        mall.mallOwners
          .map(({ name, surname }) => `${name} ${surname}`)
          .join(', ') || '-'
      ).toLowerCase();

      return [name, owners, managers].find((str) => str.indexOf(search) > -1);
    })
    .map((mall, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: mall.id,
      name: mall.name,
      managers:
        mall.mallMangers
          .map(({ name, surname }) => `${name} ${surname}`)
          .join(', ') || '-',
      owners:
        mall.mallOwners
          .map(({ name, surname }) => `${name} ${surname}`)
          .join(', ') || '-',
      stores: mall.totalStores,
      users: mall.totalUsers,
    }));
};
