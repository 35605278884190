import dayjs from 'dayjs';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  setConsolidatedFood,
  setConsolidatedIncome,
  setTopMallCard,
  setTopPercentageGrowth,
  setTopStoreCard,
  setTotalIncomeCard,
} from '../../Redux/totalIncomeCardsReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherTotalIncome() {
  yield takeEvery(ActionTypes.GET_TOTAL_INCOME, workerTotalIncome);
}
export function* workerTotalIncome({ payload }) {
  const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
  const tax =
    payload?.tax?.toLowerCase() == 'inkludert'
      ? 'Included'
      : payload?.tax?.toLowerCase() == 'ekskludert'
      ? 'Excluded'
      : payload?.tax;
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const start = !!payload?.date?.length
    ? payload.date[0]?.format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload?.date?.length
    ? payload.date[1]?.format('YYYY-MM-DD')
    : endOfMonth;
  const res = yield call(
    axiosInstance.get,
    `/incomes/totals?from=${start}&to=${end}${
      payload?.mallId ? `&mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      tax ? `&tax=${tax.toLowerCase()}` : ''
    }`,
  );
  yield put(setTotalIncomeCard(res));
}
export function* watcherGetTopStoreIncome() {
  yield takeEvery(ActionTypes.GET_TOP_STORE_INCOME, workerGetTopStoreIncome);
}
export function* workerGetTopStoreIncome({ payload }) {
  const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const tax =
    payload?.tax == 'Inkludert'
      ? 'Included'
      : payload?.tax == 'Ekskludert'
      ? 'Excluded'
      : payload?.tax;
  const start = !!payload?.date?.length
    ? payload?.date[0]?.format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload?.date?.length
    ? payload.date[1]?.format('YYYY-MM-DD')
    : endOfMonth;

  const res = yield call(
    axiosInstance.get,
    `/incomes/totals/top-stores?from=${start}&to=${end}${
      payload?.mallId ? `&mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      tax ? `&tax=${tax.toLowerCase()}` : ''
    }`,
  );
  yield put(setTopStoreCard(res));
}

export function* watcherGetTopMallIncome() {
  yield takeEvery(ActionTypes.GET_TOP_MALL_INCOME, workerGetTopMallIncome);
}
export function* workerGetTopMallIncome({ payload }) {
  const tax =
    payload?.tax == 'Inkludert'
      ? 'Included'
      : payload?.tax == 'Ekskludert'
      ? 'Excluded'
      : payload?.tax;
  const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const start = !!payload?.date?.length
    ? payload.date[0]?.set('hour', 0).format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload?.date?.length
    ? payload.date[1]?.set('hour', 23).format('YYYY-MM-DD')
    : endOfMonth;

  const res = yield call(
    axiosInstance.get,
    `/incomes/totals/top-malls?from=${start}&to=${end}${
      payload?.mallId ? `&mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      tax ? `&tax=${tax.toLowerCase()}` : ''
    }`,
  );
  yield put(setTopMallCard(res));
}

export function* watcherGetTopPercentageGrowth() {
  yield takeEvery(
    ActionTypes.GET_TOP_PERCENTAGE_GROWTH,
    workerGetTopPercentageGrowth,
  );
}
export function* workerGetTopPercentageGrowth({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/incomes/top-percentage-growth?tax=${
      payload?.tax?.toLowerCase() || 'excluded'
    }`,
  );
  yield put(setTopPercentageGrowth(res));
}

export function* watcherGetConsolidatedIncome() {
  yield takeEvery(
    ActionTypes.GET_CONSOLIDATED_INCOME,
    workerGetConsolidatedIncome,
  );
}
export function* workerGetConsolidatedIncome({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/incomes/consolidated-income-accumulated?tax=${
      payload?.tax?.toLowerCase() || 'excluded'
    }`,
  );
  yield put(setConsolidatedIncome(res));
}

export function* watcherGetConsolidatedFood() {
  yield takeEvery(ActionTypes.GET_CONSOLIDATED_FOOD, workerGetConsolidatedFood);
}
export function* workerGetConsolidatedFood({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/incomes/consolidated-food-beverage?tax=${
      payload?.tax?.toLowerCase() || 'excluded'
    }`,
  );
  yield put(setConsolidatedFood(res));
}
