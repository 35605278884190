const defaultOptions = { emptyValue: '-', hideSecond: false };

const addSpaces = (value, options = {}) => {
  const emptyValue = options.emptyValue || defaultOptions.emptyValue;
  const hideSecond = options.hideSecond || defaultOptions.hideSecond;
  try {
    if (isNaN(Number(value)) || value === null) return emptyValue;
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts?.[1]?.length > 2) parts[1] = parts[1].slice(0, 2);
    if (hideSecond) return parts[0];
    return parts.join('.');
  } catch {
    console.log('catched error => ', value);
    return emptyValue;
  }
};

export default addSpaces;
