import { ActionTypes } from './actionsTypes';

export const getStoreIncome = (payload) => ({
  type: ActionTypes.GET_STORE_INCOME,
  payload,
});
export const addIncomeForStore = (payload) => ({
  type: ActionTypes.ADD_INCOME,
  payload,
});
export const exportIncome = (payload) => ({
  type: ActionTypes.EXPORT_INCOME,
  payload,
});
