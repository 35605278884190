import { TAX } from '../../../utils/constants';
import { ActionTypes } from './actionTypes';

export const TOP_CARD_TYPES = {
  CONSOLIDATED_INCOME: 'consolidatedIncome',
  TOP_PERCENTAGE_GROWTH: 'topPercentageGrowth',
  CONSOLIDATED_FOOD: 'consolidatedFood',
};

const initialState = {
  totalIncomeCard: [],
  topStoreCard: [],
  topMallCard: [],
  totalIncomeAmount: {},
  [TOP_CARD_TYPES.CONSOLIDATED_FOOD]: null,
  [TOP_CARD_TYPES.TOP_PERCENTAGE_GROWTH]: null,
  [TOP_CARD_TYPES.CONSOLIDATED_FOOD]: null,
  tax: TAX.EXCLUDED,
};

export const totalIncomeCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TOTAL_INCOME_CARD:
      return {
        ...state,
        totalIncomeCard: action.payload,
      };
    case ActionTypes.SET_TOP_STORE_CARD:
      return {
        ...state,
        topStoreCard: action.payload,
      };
    case ActionTypes.SET_TOP_MALL_CARD:
      return {
        ...state,
        topMallCard: action.payload,
      };

    case ActionTypes.SET_CONSOLIDATED_INCOME:
      return {
        ...state,
        consolidatedIncome: action.payload,
      };

    case ActionTypes.SET_TOP_PERCENTAGE_GROWTH:
      return {
        ...state,
        topPercentageGrowth: action.payload,
      };
    case ActionTypes.SET_CONSOLIDATED_FOOD:
      return {
        ...state,
        consolidatedFood: action.payload,
      };

    case ActionTypes.SET_TOTAL_INCOME_AMOUNT:
      return {
        ...state,
        totalIncomeAmount: {
          ...state.totalIncomeAmount,
          [action.payload.tableUID]: action.payload.res,
        },
      };
    case ActionTypes.CLEAR_TOTAL_INCOME_AMOUNT:
      return {
        ...state,
        totalIncomeAmount: {},
      };
    case ActionTypes.SET_TAX:
      return {
        ...state,
        tax: action.payload,
      };
    default:
      return state;
  }
};
