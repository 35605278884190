import { Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import './styles.scss';
import Triangle from '../../assets/icons/Triangle';

const today = dayjs();

function createYearObjects() {
  let yearObjects = [];
  for (let year = 2010; year <= today.$y; year++) {
    let yearObject = {
      value: year,
      label: year,
    };
    yearObjects.push(yearObject);
  }
  return yearObjects.reverse();
}

const years = createYearObjects();

function YearPicker({ value, onChange }) {
  return (
    <Select
      value={value || today.$y}
      style={{ minWidth: 72 }}
      onChange={onChange}
      dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)' }}
      options={years}
      className='YearPicker'
      suffixIcon={<Triangle />}
    />
  );
}

export default YearPicker;
