import React from 'react';
import AdminEditFormSelect from '../AdminEditFormSelect';
import './styles.scss';

function AdminEditFormMultipleSelect({
  values = [],
  options = [],
  onChange,
  name = '',
  onBlur,
  error = '',
}) {
  const onDelete = (index) => {
    onChange(values.toSpliced(index, 1));
  };

  const onInnerChange = (value, index) => {
    onChange(values.toSpliced(index, 1, value));
  };

  const onAddNew = (value) => {
    onChange([...values, value]);
  };

  const availableOptions = options.filter(
    (x) => !values.find((val) => val === x.value),
  );

  return (
    <div className='AEFMS'>
      {name && <div className='AEFMS__name'>{name}</div>}
      {values.map((value, index) => (
        <AdminEditFormSelect
          value={
            options.find((option) => value === option.value)?.label || value
          }
          options={availableOptions}
          placeholder={`Select ${name}`}
          onDeleteClick={() => onDelete(index)}
          onChange={(val) => onInnerChange(val, index)}
          onBlur={onBlur}
        />
      ))}
      <div className='AEFMS__addNew'>
        <AdminEditFormSelect
          options={availableOptions}
          placeholder={`Add ${name}`}
          onChange={onAddNew}
          onBlur={onBlur}
          value={null}
        />
      </div>
      {error && <div className='AEFMS_error'>{error}</div>}
    </div>
  );
}

export default AdminEditFormMultipleSelect;
