import { ActionTypes } from './actionTypes';

const initialState = {
  visible: false,
  visibleCharts: true,
};

export const leftMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IS_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    case ActionTypes.SET_IS_VISIBLE_CHARTS:
      return {
        ...state,
        visibleCharts: action.payload,
      };
    default:
      return state;
  }
};
