import React from 'react';

const HideDown = (props) => {
  return (
    <svg width='22' height='22' fill='none' viewBox='0 0 16 16' {...props}>
      <path
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={0.72}
        d='M12.5 8.5L8 13 3.5 8.5M12.5 3L8 7.5 3.5 3'
      />
    </svg>
  );
};

export default HideDown;
