import React from 'react';
import { useNavigate, useParams } from 'react-router';
import axiosInstance from '../../../../../services/Axios/axiosInstance';
import AdminApi from '../../../../../services/Axios/AdminApi';
import ROUTES from '../../../../../routes/route';
import showNotification from '../../../../../utils/showNotification';

function useMallInfo() {
  const [isEdit, setIsEdit] = React.useState(false);
  const [mall, setMall] = React.useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] =
    React.useState(false);
  const { mallId } = useParams();
  const navigate = useNavigate();

  const fetchMall = (mallId) => {
    AdminApi.getMall(mallId).then((res) => {
      setMall(res);
    });
  };

  const changeMall = async (data) => {
    await AdminApi.updateMall(mallId, data)
      .then((res) => {
        setMall(res);
        setIsEdit(false);
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
    return true;
  };

  const deleteMall = async () => {
    await AdminApi.deleteMall(mallId).catch((err) => {
      showNotification({
        content: err?.response?.data?.userMessage,
        type: 'error',
      });
    });
    return true;
  };

  const onDeleteConfirm = () => {
    setIsDeleteConfirmVisible(false);
    navigate(ROUTES.ADMIN.MALLS.LIST);
  };

  React.useEffect(() => {
    if (mallId) fetchMall(mallId);
  }, [mallId]);

  return {
    mall,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteMall,
    onDeleteConfirm,
    changeMall,
  };
}

export default useMallInfo;
