import React from 'react';
import './styles.scss';
import { Button } from '@mui/material';
import ArrowNext from '../../../../assets/icons/ArrowNext';

const PER_PAGE = 10;

function AdminTablePagination({
  onPaginationChange,
  pagination = { total: 10, page: 1 },
}) {
  const currentPage = pagination.page;

  const pages = pagination.total
    ? pagination.total > 10
      ? Math.floor(pagination.total / PER_PAGE) +
        (pagination.total % 10 === 0 ? 0 : 1)
      : 1
    : 1;

  const isGoToPrevAvailable = currentPage - 1 > 0;
  const isGoToNextAvailable = currentPage + 1 <= pages;

  const goToPrev = () => {
    if (isGoToPrevAvailable) onPaginationChange(currentPage - 1);
  };

  const goToNext = () => {
    if (isGoToNextAvailable) onPaginationChange(currentPage + 1);
  };

  const shouldShowThreeDots = pages > 6;
  const isLastSix = currentPage >= pages - 5;

  return (
    <div className='AdminTablePagination'>
      <Button
        disabled={!isGoToPrevAvailable}
        startIcon={<ArrowNext style={{ transform: 'rotate(180deg)' }} />}
        onClick={goToPrev}
      >
        Back
      </Button>
      <div className='paginationButtonsWrapper'>
        {shouldShowThreeDots ? (
          <>
            {[currentPage, currentPage + 1, currentPage + 2].map((i) => (
              <Button
                onClick={() => onPaginationChange(i)}
                className={`paginationButton ${
                  i === currentPage ? 'active' : ''
                }`}
              >
                {i}
              </Button>
            ))}
            {!isLastSix && <Button className={`paginationButton`}>...</Button>}

            {[pages - 2, pages - 1, pages].map((i) => (
              <Button
                onClick={() => onPaginationChange(i)}
                className={`paginationButton ${
                  i === currentPage ? 'active' : ''
                }`}
              >
                {i}
              </Button>
            ))}
          </>
        ) : (
          Array(pages)
            .fill(1)
            .map((_, i) => (
              <Button
                onClick={() => onPaginationChange(i + 1)}
                className={`paginationButton ${
                  i + 1 === currentPage ? 'active' : ''
                }`}
              >
                {i + 1}
              </Button>
            ))
        )}
      </div>
      <Button
        disabled={!isGoToNextAvailable}
        endIcon={<ArrowNext />}
        onClick={goToNext}
      >
        Next
      </Button>
      <div style={{ marginLeft: 12 }}>{pages} Pages</div>
    </div>
  );
}

export default AdminTablePagination;
