import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';
import { store } from '../index';
import { logOutRed, setUser } from '../Redux/loginReducer/action';

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const onRequest = (config) => {
  const storageData = store.getState().loginReducer;

  if (storageData?.accessToken) {
    config.headers.Authorization = `Bearer ${storageData.accessToken}`;
  }

  return config;
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);

  return Promise.reject(error);
};

const onResponse = (response) => {
  return response.data;
};

const onResponseError = (axiosInstance) => async (error) => {
  const originalRequest = error.config;
  console.log({ error });

   if (error.response.status === 401) return store.dispatch(logOutRed());
  if (error.response.status !== 403) return Promise.reject(error);

  if (!isAlreadyFetchingAccessToken) {
    isAlreadyFetchingAccessToken = true;

    const loginReducer = store.getState().loginReducer;

    axios
      .put(
        `${MAIN_URL}/v1/auth/refresh-tokens`,
        {},
        { headers: { 'x-refresh-token': loginReducer?.refreshToken } },
      )
      .then(({ data }) => {
        isAlreadyFetchingAccessToken = false;

        store.dispatch(setUser(data));

        onAccessTokenFetched(data.accessToken);
      })
      .catch((error) => {
        store.dispatch(logOutRed());
        //window.location.reload();
        //window.location.replace('/');
      });
  }

  const retryOriginalRequest = new Promise((resolve) => {
    addSubscriber((accessToken) => {
      originalRequest.headers.Authorization = 'Bearer ' + accessToken;

      resolve(axiosInstance(originalRequest));
    });
  });

  return retryOriginalRequest;
};

//* Setup interceptors to the axios instance
const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(
    onResponse,
    onResponseError(axiosInstance),
  );

  return axiosInstance;
};

export default setupInterceptorsTo;
