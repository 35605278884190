import React from 'react';

const HideUp = (props) => {
  return (
    <svg width='22' height='22' fill='none' viewBox='0 0 22 22' {...props}>
      <path
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={0.72}
        d='M4.813 10.313L11 4.125l6.188 6.188M4.813 17.875L11 11.687l6.188 6.188'
      />
    </svg>
  );
};

export default HideUp;
