import { ActionTypes } from './actionTypes';

export const setMalls = (payload) => ({
  type: ActionTypes.SET_MALLS,
  payload,
});
export const setStores = (payload) => ({
  type: ActionTypes.SET_STORES,
  payload,
});
export const setStoresNewUser = (payload) => ({
  type: ActionTypes.SET_STORES_NEW_USER,
  payload,
});
export const setStoreGroups = (payload) => ({
  type: ActionTypes.SET_STORE_GROUPS,
  payload,
});
