import React, { useContext } from 'react';
import './styles.scss';
import addSpaces from '../../../../utils/addSpaces';
import { getColumnTotal } from '../../utils/getTotal';
import { getShownMalls, getShownStores } from '../../utils/getShown';
import { TableContext } from '../Table/TableContext';
import { getDiffPercentage } from '../../utils/getDiff';

function TableTotal() {
  const { shownColumns, tableSettings, isEdit, shouldDisplayAdd } =
    useContext(TableContext);

  const { malls, hiddenStores, hiddenMalls } = tableSettings;

  const allShownMalls = getShownMalls({
    malls,
    isEdit,
    hiddenMalls,
  });

  const allShownStores = allShownMalls.reduce(
    (cur, mall) => [
      ...cur,
      ...getShownStores({
        stores: mall.stores,
        isEdit,
        hiddenStores,
      }),
    ],
    [],
  );

  const totals = shownColumns.map((column) => ({
    ...column,
    data: getColumnTotal(
      allShownStores,
      column,
      shownColumns.find((x) => x.isVisible),
    ),
  }));

  return (
    <div className='TableTotal'>
      <div className='TableTotal__total' style={{ minWidth: 160 }}>
        Total
      </div>
      {shouldDisplayAdd && <div style={{ minWidth: 100, width: '100%' }} />}
      {totals.map((x) => (
        <div
          className='TableTotal__cell'
          style={{ opacity: !x.isVisible ? 0.6 : 1 }}
        >
          {x?.diffType === 'percentage'
            ? getDiffPercentage(totals[0].data, totals[1].data) === '-'
              ? '0'
              : getDiffPercentage(totals[0].data, totals[1].data) + ' %'
            : addSpaces(x.data, { hideSecond: true })}
        </div>
      ))}
      {shouldDisplayAdd && <div style={{ minWidth: 100, width: '100%' }} />}
      <div className='TableTotal__cell' />
    </div>
  );
}

export default TableTotal;
