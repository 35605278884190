import dayjs from 'dayjs';
import { call, put, takeEvery } from 'redux-saga/effects';
import { setTotalIncomeAmount } from '../../Redux/totalIncomeCardsReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetTotalIncomeAmount() {
  yield takeEvery(
    ActionTypes.GET_TOTAL_INCOME_AMOUNT,
    workerGetTotalIncomeAmount,
  );
}
export function* workerGetTotalIncomeAmount({ payload }) {
  try {
    const body = {
      dates: payload.columns,
    };
    const { storeGroupId } = payload.filters || {};
    const res = yield call(
      axiosInstance.post,
      `/incomes/amounts?${
        storeGroupId ? `storeGroupsIds=${storeGroupId}` : ''
      }`,
      body,
    );
    if (res) {
      yield put(setTotalIncomeAmount({ res, tableUID: payload.tableUID }));
    }
  } catch {}
}

export function* watcherExportTotalIncomeAmount() {
  yield takeEvery(
    ActionTypes.EXPORT_TOTAL_INCOME_AMOUNT,
    workerExportTotalIncomeAmount,
  );
}

export function* workerExportTotalIncomeAmount({ payload }) {
  const currentYear = dayjs().get('year');
  const previousYear = dayjs().add(-1, 'year').get('year');
  const body = {
    dates: payload.addedColumn,
  };
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };
  axiosInstance({
    url: `/incomes/amounts/exports?${
      payload?.mallId ? `mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeGroupsId ? `&storeGroupsIds=${payload?.storeGroupsId}` : ''
    }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
      payload?.tax ? `&tax=${payload?.tax.toLowerCase()}` : ''
    }&includeCurrentYear=${
      payload.checked[`${currentYear} (kr)`]
    }&includePreviousYear=${
      payload.checked[`${previousYear} (kr)`]
    }&includeMoneyDifference=${
      payload.checked['Diff (kr)']
    }&includePercentDifference=${payload.checked['Diff (%)']}`,
    method: 'POST',
    headers,
    data: body,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}
