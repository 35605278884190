import React from 'react';

const ArrowNext = (props) => {
  return (
    <svg width='6' height='9' fill='none' viewBox='0 0 6 9' {...props}>
      <path
        stroke='#FAFAFA'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity='0.4'
        d='M1.313 1.125L4.688 4.5 1.313 7.875'
      />
    </svg>
  );
};

export default ArrowNext;
