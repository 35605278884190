import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NOTIFICATION_CSS_CLASSES } from './constants';


const showNotification = ({ content, options, type }) => {
  toast[type](content, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'dark',
    className: NOTIFICATION_CSS_CLASSES[type],
    ...options,
  });
};

export default showNotification;
