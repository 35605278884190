import React from 'react';
import { months } from '../../utils/constants';
import './styles.scss';
import dayjs from 'dayjs';

function MonthsComponent({ selectedMonth, setSelectedMonth, selectedYear }) {
  const today = dayjs();

  const getIsDisabled = (index) => {
    if (selectedYear === today.$y) return index > today.$M;
    return selectedYear > today.$y;
  };

  return (
    <div className='listMonths'>
      {months().map((item, index) => (
        <button
          onClick={() => setSelectedMonth(item)}
          disabled={getIsDisabled(index)}
          className='listBorderlessButton'
          key={index}
        >
          <text
            className={
              getIsDisabled(index)
                ? 'listMonthsTitleGrey'
                : selectedMonth.number == item.number
                ? 'listMonthsTitleYellow'
                : 'listMonthsTitleWhite'
            }
          >
            {item.name}
          </text>
        </button>
      ))}
    </div>
  );
}

export default MonthsComponent;
