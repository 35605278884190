import React from 'react';
import { Header } from '../../../../../components/Header/Header';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { LeftSideMenu } from '../../../../../components/LeftMenu/LeftMenu';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import useStoreInfo from './useStoreInfo';
import ConfirmModal from '../../../../../components/ConfirmModal';
import EditForm from './EditForm';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../routes/route';
import dayjs from 'dayjs';

function StoreInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    store,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteStore,
    onDeleteConfirm,
    changeStore,
  } = useStoreInfo();

  return (
    <>
      <Header
        goBack={{
          title: t('stores'),
          onClick: () => navigate(ROUTES.ADMIN.STORES.LIST),
        }}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <LeftSideMenu selected='ADMIN' />
        <div className='AdminInfo'>
          <div className='AdminInfo__container'>
            {isEdit ? (
              <EditForm
                store={store}
                onCancel={() => setIsEdit(false)}
                onSave={changeStore}
              />
            ) : (
                <>
                    <div className='AdminInfo__container__controls'>
                        <FormOutlined onClick={() => setIsEdit(true)}/>
                        <DeleteOutlined
                            onClick={() => setIsDeleteConfirmVisible(true)}
                        />
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('adminTable.storeName')}:</div>
                        <div className='white'>{store?.name || '-'}</div>
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('adminTable.mall')}:</div>
                        <div className='white'>{store?.mall?.name}</div>
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('adminTable.storeManager')}:</div>
                        <div className='white'>
                            {store?.storeManager
                                ? store?.storeManager?.name +
                                ' ' +
                                store?.storeManager?.surname
                                : '-'}
                        </div>
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('adminTable.tax')}:</div>
                        <div className='white'>{store?.tax || '-'}</div>
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('start_Date')}:</div>
                        <div className='white'>
                            {store?.startDate
                                ? dayjs(store?.startDate).format('DD.MM.YYYY')
                                : '-'}
                        </div>
                    </div>
                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('end_Date')}:</div>
                        <div className='white'>
                            {store?.endDate
                                ? dayjs(store?.endDate).format('DD.MM.YYYY')
                                : '-'}
                        </div>
                    </div>

                    <div className='AdminInfo__container__wrapper'>
                        <div className='grey'>{t('pos_Start_At')}:</div>
                        <div className='white'>
                            {store?.posStartAt
                                ? dayjs(store?.posStartAt).format('DD.MM.YYYY')
                                : '-'}
                        </div>
                    </div>
                </>
            )}
              {isDeleteConfirmVisible && (
                  <ConfirmModal
                      onConfirm={deleteStore}
                      onCancel={() => setIsDeleteConfirmVisible(false)}
                      text={`${t('deleteStore1')} ${store?.name} ${t(
                          'deleteStore2',
                      )}`}
                      confirmBtnText={t('delete')}
                confirmDoneText={`${store?.name} ${t('storeDeleted')}`}
                confirmDoneBtnText={t('continue')}
                onConfirmDone={onDeleteConfirm}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreInfo;
