import { ActionTypes } from './actionTypes';

export const setUser = (payload) => ({
  type: ActionTypes.SET_USER,
  payload,
});
export const setError = (payload) => ({
  type: ActionTypes.SET_ERROR,
  payload,
});
export const logOutRed = () => ({
  type: ActionTypes.LOG_OUT,
});
export const setIsLoading = (payload) => ({
  type: ActionTypes.SET_IS_LOADING,
  payload,
});
