import React, { useContext } from 'react';
import TableMallComponent from '../TableMallComponent';
import TableHeader from '../TableHeader';
import TableTotal from '../TableTotal';
import { TableContext, TableProvider } from './TableContext';
import { IncomeTableContext } from '../../IncomeTableContext';

function Table({ tableUID }) {
  const { tablesSettings } = useContext(IncomeTableContext);

  if (tablesSettings[tableUID])
    return (
      <TableProvider tableUID={tableUID}>
        <div>
          <TableHeader />
          <TableContext.Consumer>
            {({ malls }) => (
              <>
                {malls.map((mall) => (
                  <TableMallComponent mall={mall} key={mall.id} />
                ))}
              </>
            )}
          </TableContext.Consumer>
          <TableTotal />
        </div>
      </TableProvider>
    );

  return <></>;
}

export default Table;
