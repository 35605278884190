import React from 'react';
import './styles.scss';
import { TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

function AdminFormInput({
  value,
  onChange,
  placeholder = 'Placeholder',
  name = 'Field name',
  error = '',
  onBlur,
}) {
  return (
    <div className='AdminFormInput'>
      <div className='AdminFormInput__name'>{name}</div>
      <div>
        <TextField
          className='AdminFormInput__input'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          variant='filled'
          onBlur={onBlur}
          style={error ? { borderColor: '#D92828' } : {}}
        />
        {error && <div className='AdminFormInput__error'>{error}</div>}
      </div>
    </div>
  );
}

export default AdminFormInput;

export const AdminFormPhoneInput = ({
  value,
  onChange,
  placeholder = 'Placeholder',
  name = 'Field name',
  error = '',
  onBlur,
}) => {
  return (
    <div className='AdminFormInput'>
      <div className='AdminFormInput__name'>{name}</div>
      <div>
        <ReactInputMask
          mask='+47 999 99 999'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={error ? { borderColor: '#D92828' } : {}}
          disabled={false}
          onBlur={onBlur}
          maskChar='_'
        >
          {() => (
            <TextField
              className='AdminFormInput__input phone'
              placeholder={placeholder}
              variant='filled'
            />
          )}
        </ReactInputMask>
        {error && <div className='AdminFormInput__error'>{error}</div>}
      </div>
    </div>
  );
};

export const AdminFormPasswordInput = ({
  value,
  onChange,
  placeholder = 'Placeholder',
  name = 'Field name',
  error = '',
  onBlur,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div className='AdminFormInput'>
      <div className='AdminFormInput__name'>{name}</div>
      <div>
        <div>
          <TextField
            className={`AdminFormInput__input password ${
              isVisible ? 'visible' : 'hidden'
            }`}
            style={error ? { borderColor: '#D92828' } : {}}
            onBlur={onBlur}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            variant='filled'
            type='text'
          />
          <div className='eye' onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </div>
        </div>
        {error && <div className='AdminFormInput__error'>{error}</div>}
      </div>
    </div>
  );
};
