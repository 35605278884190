import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';
import setupInterceptorsTo from './setupInterceptors';

const axiosInstance = axios.create({
  baseURL: MAIN_URL + '/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

setupInterceptorsTo(axiosInstance);

export default axiosInstance;
