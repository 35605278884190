import dayjs from 'dayjs';
import { ActionTypes } from './actionTypes';

const today = dayjs();
const start = today.startOf('month');
const end = today.endOf('month');

const initialState = {
  reportingList: [],
  total: null,
  filters: {
    selectedMallId: null,
    selectedMonth: {
      name: dayjs().format('MMM'),
      number: dayjs().month() + 1,
    },
    selectedDates: [start, end],
    selectedOrder: '',
  },
};

export const reportingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REPORTING_LIST:
      return {
        ...state,
        reportingList: action.payload.data,
        total: action.payload.total,
      };
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
};
