import { ActionTypes } from './actionTypes';

export const setReportingList = (payload) => ({
  type: ActionTypes.SET_REPORTING_LIST,
  payload,
});

export const setFilters = (payload) => ({
  type: ActionTypes.SET_FILTERS,
  payload,
});
