import dayjs from 'dayjs';

const getDisabledStores = (stores, columns, shownColumns) => {
  const columnUIDs = Object.keys(columns);
  const disabledStores = [];

  stores.forEach((store) => {
    let disabledCount = 0;
    columnUIDs.forEach((columnUID) => {
      const column = shownColumns.find((x) => x.columnUID === columnUID);
      const isDisabled =
        dayjs(columns[columnUID][store.id]?.startDate).isAfter(
          dayjs(column?.to),
        ) ||
        dayjs(columns[columnUID][store.id]?.endDate).isBefore(
          dayjs(column?.from),
        );
      if (isDisabled) disabledCount++;
    });
    if (disabledCount === columnUIDs.length) disabledStores.push(store.id);
  });

  return disabledStores;
};

export const getIsStoreDisabled = ({
  shownColumns,
  columnUID,
  sortedStoreColumns,
  storeId,
}) => {
  const column = shownColumns.find((x) => x.columnUID === columnUID);
  const isDisabled =
    dayjs(sortedStoreColumns[columnUID][storeId]?.startDate).isAfter(
      dayjs(column?.to),
    ) ||
    dayjs(sortedStoreColumns[columnUID][storeId]?.endDate).isBefore(
      dayjs(column?.from),
    );

  return isDisabled;
};

export default getDisabledStores;
