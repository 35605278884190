export const getShownMalls = ({
  malls = [],
  isEdit = false,
  hiddenMalls = [],
}) => {
  return malls.filter((mall) =>
    isEdit ? true : !hiddenMalls.find((x) => x === mall.id),
  );
};

export const getShownStores = ({
  stores = [],
  isEdit = false,
  hiddenStores = [],
}) => {
  return stores.filter((store) =>
    isEdit ? true : !hiddenStores.find((x) => x === store.id),
  );
};
