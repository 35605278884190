import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSelector } from 'react-redux';
import addSpaces from '../../../../utils/addSpaces';
import { TOP_CARD_TYPES } from '../../../../services/Redux/totalIncomeCardsReducer';
import dayjs from 'dayjs';

// type consolidatedIncome topPercentageGrowth consolidatedFood
function TopCard({ type }) {
  const { t } = useTranslation();
  const data = useSelector((state) => state.totalIncomeCardReducer[type]);

  const getFormatedDate = (date) => {
    if (!date) return '-';
    if (type === TOP_CARD_TYPES.TOP_PERCENTAGE_GROWTH)
      return dayjs(date).format('MMMM YYYY');
    return dayjs(date).format('DD.MM.YY') + '<';
  };

  return (
    <div className='TopCard'>
      <div className='TopCard__title'>{t(type)}</div>
      {data?.name && <div className='TopCard__mallName'>{data?.name}</div>}
      <div className='TopCard__data'>
        <div className='TopCard__data__columns'>
          <span className='TopCard__data__date'>
            {getFormatedDate(data?.periodDate?.[0])}
          </span>
          <span className='TopCard__data__date'>
            {getFormatedDate(data?.prevPeriodDate?.[0])}
          </span>
        </div>
        <div>
          <div className='TopCard__data__columns'>
            <span className='TopCard__data__numbers'>
              {data?.period ? addSpaces(data?.period) : '-'}
            </span>
            <span className='TopCard__data__numbers'>
              {data?.prevPeriod ? addSpaces(data?.prevPeriod) : '-'}
            </span>
          </div>
          {![null, undefined].includes(data?.difference) && (
            <span
              className='TopCard__data__percents'
              style={{ color: data?.difference >= 0 ? '#77BE1D' : '#D92828' }}
            >
              {Number(data?.difference).toFixed(2)}%
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopCard;
