import React, { useContext } from 'react';
import { TableContext } from '../Table/TableContext';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IncomeTableContext } from '../../IncomeTableContext';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import Triangle from '../../../../assets/icons/Triangle';
import { useTranslation } from 'react-i18next';
import Sort from '../../../../assets/icons/Sort';
import DateSelectorButton from '../DateSelector/DateSelectorButton';

function HeaderColumnItem({ column }) {
  const { t } = useTranslation();
  const { tableUID, isEdit } = useContext(TableContext);

  const { changeColumnType, toggleColumnTax, toggleSort, removeColumn } =
    useContext(IncomeTableContext);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.columnUID, disabled: !isEdit });

  const style = {
    transform: CSS.Transform.toString(transform),
    width: '100%',
    transition,
  };

  const types = [
    { value: 'income', label: t('income') },
    { value: 'averageTransaction', label: t('average_transaction') },
    { value: 'averageTrade', label: t('average_trade') },
  ];

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className='TableHeader__column'>
        {isEdit && (
          <div
            className='TableHeader__column__hide'
            onClick={() => removeColumn(tableUID, column.columnUID)}
          >
            <MinusCircleOutlined />
          </div>
        )}
        <div className='TableHeader__column__name'>
          <div
            className='TableHeader__column__sort'
            onClick={() => toggleSort(tableUID, column.columnUID)}
          >
            <Sort />
          </div>
          <DateSelectorButton column={column} />
        </div>
        <Select
          value={column.type || types[0].value}
          onChange={(type) =>
            changeColumnType(tableUID, column.columnUID, type)
          }
          dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)' }}
          popupClassName='TableHeader__typeSelect__dropdown'
          options={types}
          className='TableHeader__typeSelect'
          suffixIcon={<Triangle />}
        />
        {column.type !== 'averageTransaction' && (
          <div
            className='TableHeader__column__tax'
            onClick={() => toggleColumnTax(tableUID, column.columnUID)}
          >
            {t(column.tax)}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderColumnItem;
