import React from 'react';
import { Colors } from '../../utils/colors';

const Admin = ({ selected, color }) => {
  return (
    <svg width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={selected ? Colors.white : color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M13.974 4.012C11.394 3.51 10.328 3.139 8 2 5.672 3.14 4.605 3.51 2.026 4.012 1.559 12.035 7.556 14.806 8 15c.445-.194 6.441-2.965 5.974-10.988z'
      />
      <path
        fill={selected ? Colors.white : color}
        fillOpacity={selected ? '1' : '0.4'}
        d='M9.007 5.459A1.45 1.45 0 007.928 5c-.424 0-.808.162-1.08.456a1.49 1.49 0 00-.38 1.137c.063.86.718 1.56 1.46 1.56.743 0 1.396-.7 1.46-1.56a1.486 1.486 0 00-.38-1.134zM8 13.5c-.5 0-3-2-2.991-2.759.118-.66.49-1.213 1.072-1.602a3.359 3.359 0 011.847-.535c.674 0 1.33.19 1.848.535.582.389 1.072.764 1.072 1.602C10.848 11.579 8.5 13.5 8 13.5z'
      />
    </svg>
  );
};

export default Admin;
