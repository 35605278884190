import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { mallsReducer } from './mallsReducer';
import { storeIncomeReducer } from './storeIncomeReducer';
import { leftMenuReducer } from './leftMenuReducer';
import { totalIncomeCardReducer } from './totalIncomeCardsReducer';
import { reportingListReducer } from './reportingListReducer';
import { filtersReducer } from './filtersReducer';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import createFilter from 'redux-persist-transform-filter';

const saveToken = createFilter('loginReducer', ['accessToken', 'refreshToken']);

const reducers = combineReducers({
  loginReducer,
  mallsReducer,
  storeIncomeReducer,
  leftMenuReducer,
  totalIncomeCardReducer,
  reportingListReducer,
  filtersReducer,
});
const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['loginReducer'],
  transforms: [saveToken],
};

const rootReducer = persistReducer(persistConfig, reducers);
export default rootReducer;
