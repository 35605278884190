import { ActionTypes } from './actionTypes';

const initialState = {
  allMalls: [],
  allMallsUsers: [],
  allStores: [],
  allStoresGroup: [],
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_MALLS:
      return {
        ...state,
        allMalls: action.payload,
      };
    case ActionTypes.SET_ALL_MALLS_USERS:
      return {
        ...state,
        allMallsUsers: action.payload,
      };
    case ActionTypes.SET_ALL_STORES:
      return {
        ...state,
        allStores: action.payload,
      };
    case ActionTypes.SET_ALL_STORES_GROUP:
      return {
        ...state,
        allStoresGroup: action.payload,
      };
    default:
      return state;
  }
};
