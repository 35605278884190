import { ActionTypes } from './actionsTypes';

export const getAllMalls = (payload) => ({
  type: ActionTypes.GET_ALL_MALLS,
  payload,
});
export const getAllMallsUsers = (payload) => ({
  type: ActionTypes.GET_ALL_MALLS_USERS,
  payload,
});
export const getAllStores = (payload) => ({
  type: ActionTypes.GET_ALL_STORES,
  payload,
});
export const getAllStoresGroup = (payload) => ({
  type: ActionTypes.GET_ALL_STORES_GROUP,
  payload,
});