import React from 'react';
import useMallTable from './useMallTable';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { LeftSideMenu } from '../../../../../components/LeftMenu/LeftMenu';
import { Header } from '../../../../../components/Header/Header';
import AdminTable from '../../../components/AdminTable';
import { Tooltip } from 'antd';
import AdminTablesController from '../../../components/AdminTablesController';
import AddMall from '../../../components/modals/AddMall';
import AddNewButton from '../../../components/AddNewButton';
import { Link } from 'react-router-dom';
import SearchInput from '../../../components/SearchInput';
import {
  getMallInfoLink,
  getMallStoresLink,
  getMallUsersLink,
} from '../../../../../routes/route';

function MallTable() {
  const {
    malls,
    searchText,
    isModalVisible,
    setIsModalVisible,
    setSearchText,
    onMallsCreated,
    pagination,
    onPageChange,
  } = useMallTable();
  const { t } = useTranslation();

  const mallColumns = [
    {
      title: 'Nº',
      dataIndex: 'index',
      render: (text) => <span className='adminTable__grey'>{text}</span>,
      width: 60,
    },
    {
      title: t('adminTable.mallName'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div>
          <Link to={getMallInfoLink(record.id)}>{text}</Link>
        </div>
      ),
      width: 140,
    },
    {
      title: t('adminTable.mallManagers'),
      dataIndex: 'managers',
      render: (text) => (
        <Tooltip title={text}>
          <div>{text}</div>
        </Tooltip>
      ),
      width: 240,
    },
    {
      title: t('adminTable.mallOwners'),
      dataIndex: 'owners',
      render: (text) => (
        <Tooltip title={text}>
          <div>{text}</div>
        </Tooltip>
      ),
      width: 240,
    },
    {
      title: t('adminTable.stores'),
      dataIndex: 'stores',
      sorter: (a, b) => a.stores - b.stores,
      render: (text, record) => (
        <div>
          <Link to={getMallStoresLink(record.id)}>{text}</Link>
        </div>
      ),
      width: 90,
    },
    {
      title: t('adminTable.users'),
      dataIndex: 'users',
      render: (text, record) => (
        <div>
          <Link to={getMallUsersLink(record.id)}>{text}</Link>
        </div>
      ),
      sorter: (a, b) => a.users - b.users,
      width: 90,
    },
  ];

  return (
    <>
      <Header />
      <AddMall
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
        onMallsCreated={onMallsCreated}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <LeftSideMenu selected='ADMIN' />
        <div style={{ marginLeft: 12, width: 'calc(100% - 60px)' }}>
          <AdminTable
            columns={mallColumns}
            dataSource={malls}
            pagination={pagination}
            onPaginationChange={onPageChange}
            header={
              <div style={{ margin: '16px 0' }}>
                <div className='adminTable__header'>
                  <AdminTablesController />
                  <AddNewButton
                    text={t('add_New_Mall')}
                    onClick={() => setIsModalVisible(true)}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SearchInput value={searchText} onChange={setSearchText} />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default MallTable;
