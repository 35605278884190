import React from 'react';
import { Colors } from '../../utils/colors';

const List = ({ selected, color }) => {
  return (
    <svg width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={selected ? Colors.white : color}
        strokeLinejoin='round'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M11.5 1.5h-7A1.5 1.5 0 003 3v10a1.5 1.5 0 001.5 1.5h7A1.5 1.5 0 0013 13V3a1.5 1.5 0 00-1.5-1.5z'
      />
      <path
        stroke={selected ? Colors.white : color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M5.5 4h5m-5 2.5h5M5.5 9H8'
      />
    </svg>
  );
};

export default List;
