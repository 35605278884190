import { ActionTypes } from './actionTypes';

const initialState = {
  malls: [],
  stores: [],
  groups: [],
  newUserStores: [],
};

export const mallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MALLS:
      return {
        ...state,
        malls: action.payload,
      };
    case ActionTypes.SET_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    case ActionTypes.SET_STORES_NEW_USER:
      return {
        ...state,
        newUserStores: action.payload,
      };
    case ActionTypes.SET_STORE_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    default:
      return state;
  }
};
