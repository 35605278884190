import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Admin from '../../assets/icons/Admin';
import Dashboard from '../../assets/icons/Dashboard';
import Income from '../../assets/icons/Income';
import List from '../../assets/icons/List';
import { Colors } from '../../utils/colors';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ROLES } from '../../pages/AdminPanel/utils/formatForTables';

export const LeftSideMenu = ({ selected }) => {
  const user = useSelector((state) => state.loginReducer.user);
  const [dashboardColor, setDashboardColor] = useState('#FAFAFA');
  const [listColor, setListColor] = useState('#FAFAFA');
  const [incomeColor, setIncomeColor] = useState('#FAFAFA');
  const [adminColor, setAdminColor] = useState('#FAFAFA');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onTitlePress = (key) => {
    navigate(key);
  };
  return (
    <div
      style={{
        backgroundColor: Colors.mainBlack,
        width: '64px',
      }}
      className='containerMenu'
    >
      <div className='wrapperMenu'>
        <div className='iconContainer'>
          <Tooltip title={t('dashboard')} placement='right'>
            <button
              className='simpleButton'
              onClick={() => onTitlePress('/dashboard')}
              onMouseEnter={() => setDashboardColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setDashboardColor('#FAFAFA')}
            >
              <Dashboard
                selected={selected === 'HOME'}
                color={dashboardColor}
              />
            </button>
          </Tooltip>
          {user?.roles[0] == ROLES.ADMIN ||
          user?.roles[0] == ROLES.MALL_MANAGER ||
          user?.roles[0] == ROLES.MALL_OWNER ? (
            <Tooltip title={t('reporting_List')} placement='right'>
              <button
                className='buttonWithPadding'
                onClick={() => onTitlePress('/list')}
                onMouseEnter={() => setListColor('rgba(218, 253, 0, 1)')}
                onMouseLeave={() => setListColor('#FAFAFA')}
              >
                <List selected={selected === 'LIST'} color={listColor} />
              </button>
            </Tooltip>
          ) : (
            <></>
          )}
          {user?.roles[0] === ROLES.STORE_MANAGER ? (
            <Tooltip title={t('report_Income')} placement='right'>
              <button
                className='buttonWithPadding'
                onClick={() => onTitlePress('/income')}
                onMouseEnter={() => setIncomeColor('rgba(218, 253, 0, 1)')}
                onMouseLeave={() => setIncomeColor('#FAFAFA')}
              >
                <Income selected={selected == 'INCOME'} color={incomeColor} />
              </button>
            </Tooltip>
          ) : (
            <></>
          )}
          {user?.roles[0] === ROLES.ADMIN ? (
            <Tooltip title={t('admin_Panel')} placement='right'>
              <button
                className='buttonWithPadding'
                onClick={() => onTitlePress('/admin/malls')}
                onMouseEnter={() => setAdminColor('rgba(218, 253, 0, 1)')}
                onMouseLeave={() => setAdminColor('#FAFAFA')}
              >
                <Admin selected={selected == 'ADMIN'} color={adminColor} />
              </button>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
