import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ROUTES from '../../../../../routes/route';
import AdminApi from '../../../../../services/Axios/AdminApi';
import showNotification from '../../../../../utils/showNotification';

function useUserInfo() {
  const [isEdit, setIsEdit] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] =
    React.useState(false);
  const [malls, setMalls] = React.useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();

  const fetchUser = () => {
    AdminApi.getUser(userId).then((res) => {
      setUser(res);
    });
  };

  const changeUser = async (data) => {
    await AdminApi.updateUser(userId, data)
      .then((res) => {
        setUser(res);
        setIsEdit(false);
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
    return true;
  };

  const deleteUser = async () => {
    await AdminApi.deleteUser(userId).catch((err) => {
      showNotification({
        content: err?.response?.data?.userMessage,
        type: 'error',
      });
    });
    return true;
  };

  const onDeleteConfirm = () => {
    setIsDeleteConfirmVisible(false);
    navigate(ROUTES.ADMIN.USERS.LIST);
  };

  React.useEffect(() => {
    if (userId) fetchUser();
  }, [userId]);

  React.useEffect(() => {
    AdminApi.getMallsList({ limit: 149 }).then((res) => {
      setMalls(res.data);
    });
  }, []);

  return {
    user,
    malls,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteUser,
    onDeleteConfirm,
    changeUser,
  };
}

export default useUserInfo;
