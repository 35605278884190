import { ActionTypes } from './actionTypes';

export const setAllMalls = (payload) => ({
  type: ActionTypes.SET_ALL_MALLS,
  payload,
});
export const setAllMallsUsers = (payload) => ({
  type: ActionTypes.SET_ALL_MALLS_USERS,
  payload,
});
export const setAllStores = (payload) => ({
  type: ActionTypes.SET_ALL_STORES,
  payload,
});
export const setAllStoresGroup = (payload) => ({
  type: ActionTypes.SET_ALL_STORES_GROUP,
  payload,
});
