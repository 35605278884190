import React from 'react';
import TableController from './components/TableController';
import { IncomeTableProvider } from './IncomeTableContext';
import Tables from './components/Tables';
function IncomeTable() {
  return (
    <IncomeTableProvider>
      <div>
        <TableController />
        <Tables />
      </div>
    </IncomeTableProvider>
  );
}

export default IncomeTable;
