'use strict';

import { useFormik } from 'formik';
import React from 'react';
import AdminApi from '../../../../../services/Axios/AdminApi';
import { ROLES } from '../../../utils/formatForTables';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import showNotification from '../../../../../utils/showNotification';

function useAddMall({ onMallsCreated, close }) {
  const { t } = useTranslation();
  const [mallOwners, setMallOwners] = React.useState([]);
  const [mallManagers, setMallManagers] = React.useState([]);

  React.useEffect(() => {
    AdminApi.getUsers({ role: ROLES.MALL_MANAGER, limit: 149 }).then((res) => {
      setMallManagers(res.data);
    });
    AdminApi.getUsers({ role: ROLES.MALL_OWNER, limit: 149 }).then((res) => {
      setMallOwners(res.data);
    });
  }, []);

  const initialValues = {
    mallName: '',
    mallOwner: null,
    mallManager: null,
  };

  const validationSchema = Yup.object().shape({
    mallName: Yup.string()
      .trim('validation.trimError')
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .matches(/^[a-zA-ZæøåÆØÅ0-9 ]+$/, t('validation.shouldBeText'))
      .required(t('validation.required')),
  });

  const onSubmit = (values, { resetForm }) => {
    AdminApi.createMall({
      name: values.mallName,
      mallOwnerId: values.mallOwner || undefined,
      mallManagerId: values.mallManager || undefined,
    })
      .then((res) => {
        onMallsCreated(res);
        resetForm();
        close();
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
  };

  const { values, setFieldValue, handleSubmit, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    t,
    touched,
    handleBlur,
    mallManagers,
    mallOwners,
  };
}

export default useAddMall;
