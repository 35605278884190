import { ActionTypes } from './actionTypes';

export const setTotalIncomeCard = (payload) => ({
  type: ActionTypes.SET_TOTAL_INCOME_CARD,
  payload,
});
export const setTopStoreCard = (payload) => ({
  type: ActionTypes.SET_TOP_STORE_CARD,
  payload,
});
export const setTopMallCard = (payload) => ({
  type: ActionTypes.SET_TOP_MALL_CARD,
  payload,
});
export const setTotalIncomeAmount = (payload) => ({
  type: ActionTypes.SET_TOTAL_INCOME_AMOUNT,
  payload,
});
export const clearTotalIncomeAmount = (payload) => ({
  type: ActionTypes.CLEAR_TOTAL_INCOME_AMOUNT,
  payload,
});

export const setTopPercentageGrowth = (payload) => ({
  type: ActionTypes.SET_TOP_PERCENTAGE_GROWTH,
  payload,
});
export const setConsolidatedIncome = (payload) => ({
  type: ActionTypes.SET_CONSOLIDATED_INCOME,
  payload,
});
export const setConsolidatedFood = (payload) => ({
  type: ActionTypes.SET_CONSOLIDATED_FOOD,
  payload,
});
export const setTax = (payload) => ({
  type: ActionTypes.SET_TAX,
  payload,
});
