import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import { setReportingList } from '../../Redux/reportingListReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetReportingList() {
  yield takeEvery(ActionTypes.GET_REPORTING_LIST, workerGetReportingList);
}
export function* workerGetReportingList({ payload }) {
  const start = !!payload?.date?.length
    ? payload.date[0]?.format('YYYY-MM-DD')
    : '';
  const end = !!payload?.date?.length
    ? payload.date[1]?.format('YYYY-MM-DD')
    : '';
  if (payload?.date?.length) {
    const res = yield call(
      axiosInstance.get,
      `${MAIN_URL}/v1/stores/incomes/reports?from=${start}&to=${end}&page=${
        payload.page || 1
      }&limit=150${payload?.mallId ? `&mallIds=${payload?.mallId}` : ''}${
        payload?.storeGroupsId
          ? `&storeGroupsIds=${payload?.storeGroupsId}`
          : ''
      }${payload?.storeId ? `&storeIds=${payload?.storeId}` : ''}${
        payload?.order ? `&order=${payload?.order}` : ''
      }`,
    );
    const reportingList = yield select(
      (state) => state.reportingListReducer.reportingList,
    );
    const newData =
      payload?.page > 1
        ? { ...res, data: [...reportingList, ...res.data] }
        : res;
    yield put(setReportingList(newData));
  }
}
