import { Modal } from '@mui/material';
import React from 'react';
import AdminFormInput from '../../AdminFormInput';
import AdminFormSelect from '../../../components/AdminFormSelect';
import '../sharedStyles.scss';
import useAddStore from './useAddStore';
import GrayButton from '../../../../../components/GrayButton';
import AdminEditFormDate from '../../AdminEditFormDate';
import AdminFormDate from '../../AdminFormDate';

function AddStore({ isOpen, close, mall, onStoreCreated }) {
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    t,
    malls,
    touched,
    handleBlur,
    categories,
    mallManagers,
    i18n,
    taxes,
  } = useAddStore({ onStoreCreated, mall, close });

  return (
    <Modal open={isOpen} onClose={close} onBackdropClick={close}>
      <div className='adminModal'>
        <div>
          <h5>{mall?.name}</h5>
          <h3>{t('add_New_Store')}</h3>
          <div>
            <AdminFormInput
              name={`${t('adminTable.storeName')}*`}
              placeholder={t('adminTable.storeName')}
              value={values.storeName}
              error={touched.storeName && errors.storeName}
              onBlur={handleBlur('storeName')}
              onChange={(val) => setFieldValue('storeName', val)}
            />
            <AdminFormSelect
              name={`${t('adminTable.category')}*`}
              placeholder={t('adminTable.category')}
              value={values.category}
              error={touched.category && errors.category}
              onBlur={handleBlur('category')}
              options={categories.map((cat) => ({
                label: i18n.languages.some((x) => x === 'nor')
                  ? cat.nor
                  : cat.eng,
                value: cat.id,
              }))}
              onChange={(val) => setFieldValue('category', val)}
            />
            <AdminFormSelect
              name={`${t('adminTable.tax')}*`}
              placeholder={t('adminTable.tax')}
              value={values.tax}
              options={taxes}
              error={touched.tax && errors.tax}
              onBlur={handleBlur('tax')}
              onChange={(val) => setFieldValue('tax', val)}
            />
            <AdminFormSelect
              name={t('adminTable.storeManager')}
              placeholder={t('adminTable.storeManager')}
              value={values.storeManager}
              options={mallManagers.map((user) => ({
                label: user.name + ' ' + user.surname,
                value: user.id,
              }))}
              error={touched.storeManager && errors.storeManager}
              onBlur={handleBlur('storeManager')}
              onChange={(val) => setFieldValue('storeManager', val)}
            />
            {!mall && (
              <AdminFormSelect
                name={`${t('adminTable.mall')}*`}
                placeholder={t('adminTable.mall')}
                value={values.mall}
                options={malls.map((mall) => ({
                  label: mall.name,
                  value: mall.id,
                }))}
                error={touched.mall && errors.mall}
                onBlur={handleBlur('mall')}
                onChange={(val) => setFieldValue('mall', val)}
              />
            )}
            <AdminFormDate
              name={t('start_Date')}
              value={values.startDate}
              onChange={(val) => setFieldValue('startDate', val)}
              error={errors.startDate}
            />
            <AdminFormDate
              name={t('end_Date')}
              value={values.endDate}
              onChange={(val) => setFieldValue('endDate', val)}
              error={errors.endDate}
            />
          </div>
          <div className='adminModal__buttons'>
            <GrayButton onClick={close}>{t('cancel')}</GrayButton>
            <GrayButton onClick={handleSubmit}>{t('save')}</GrayButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddStore;
