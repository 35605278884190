import React, { useContext, useState } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import _ from 'lodash';

const TABLE_MARGIN = 56;

function useTables() {
  const { tablesSettings, selectedTable, animation, setAnimation } =
    useContext(IncomeTableContext);
  const [tablesWithDelay, setTablesWithDelay] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // used to disable transition animation at start of the page
  const tables = Object.keys(tablesSettings);

  React.useEffect(() => {
    const tablesV = Object.values(tablesSettings);
    if (
      tablesV.length > 0 &&
      tablesV.every((x) => x.malls) &&
      tablesWithDelay.length === 0
    ) {
      if (!_.isEqual(tablesWithDelay, tables)) setTablesWithDelay([...tables]);
    }
    if (tablesV.length === 0) {
      setTablesWithDelay([]);
    }
  }, [tablesSettings]);

  React.useEffect(() => {
    setIsLoading(true);
    if (tablesWithDelay.length) {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [tablesWithDelay]);

  React.useEffect(() => {
    if (!tables.length || !tablesWithDelay.length) return;

    if (tables.length === tablesWithDelay.length) {
      if (selectedTable && !_.isEqual(tablesWithDelay, tables)) {
        // move table
        const el = document.getElementById(selectedTable);
        if (!el) return;

        el.style.top = getTopOffset(tables, selectedTable) + 'px';
        el.style.zIndex = 20;

        const newSelectedTableIndex = tables.findIndex(
          (tableUID) => selectedTable === tableUID,
        );
        const oldSelectedTableIndex = tablesWithDelay.findIndex(
          (tableUID) => selectedTable === tableUID,
        );
        const bottom =
          newSelectedTableIndex > oldSelectedTableIndex
            ? tables[newSelectedTableIndex - 1]
            : tables[newSelectedTableIndex + 1];

        document.getElementById(bottom).style.top =
          getTopOffset(tables, bottom) + 'px';

        setTimeout(() => {
          setAnimation(null);
          if (!isLoading) setTablesWithDelay(tables);
          el.style.zIndex = 1 + newSelectedTableIndex;
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 1200);
      } else {
        setTimeout(() => {
          tables.forEach((tableUID) => {
            const tableEl = document.getElementById(tableUID);
            if (tableEl)
              tableEl.style.top = getTopOffset(tables, tableUID) + 'px';
          });
        }, 300);
      }
    } else if (tables.length < tablesWithDelay.length) {
      setTablesWithDelay(tables);
    } else {
      setTablesWithDelay(tables);
    }
  }, [tablesSettings, tablesWithDelay, isLoading]);

  return {
    animation,
    getClassName,
    getTopOffset,
    TABLE_MARGIN,
    tablesWithDelay,
    isLoading,
    selectedTable,
  };
}

export default useTables;

const getClassName = (currentTableUID, selectedTableUID) => {
  if (currentTableUID !== selectedTableUID) return 'opacityGray';
};

const getTopOffset = (tables = [], currentTableUID) => {
  const tableIndex = tables.findIndex(
    (tableUID) => currentTableUID === tableUID,
  );
  if (!tableIndex) return 0;
  let index = tableIndex;
  let totalHeight = 0;
  while (index >= 1) {
    const newIndex = index - 1;
    const el = document.getElementById(tables[newIndex]);
    totalHeight += el ? el.clientHeight + TABLE_MARGIN : TABLE_MARGIN;
    index--;
  }
  return totalHeight;
};
