import { ActionTypes } from './actionTypes';

const initialState = {
  storeIncome: [],
};

export const storeIncomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_STORE_INCOME:
      return {
        ...state,
        storeIncome: action.payload,
      };
    default:
      return state;
  }
};
