import * as React from 'react';
const Triangle = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={9}
    height={8}
    fill='none'
    {...props}
  >
    <path
      fill='#FAFAFA'
      fillOpacity={0.72}
      d='M3.634 6.5a1 1 0 0 0 1.732 0l1.732-3A1 1 0 0 0 6.232 2H2.768a1 1 0 0 0-.866 1.5l1.732 3Z'
    />
  </svg>
);
export default Triangle;
