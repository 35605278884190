export const toServerDate = (date) =>
  date
    .split('-')
    .map((s) => Number(s))
    .reverse()
    .join('-');

export const formatCellData = (data) => {
  if (data === null) return '-';
  if (!Number.isNaN(Number(data))) return Number(Number(data).toFixed(2));
};

export const toNumber = (number) => {
  return isNaN(Number(number)) ? 0 : Number(Number(number).toFixed(2));
};

export const getNeededCellData = (
  cellData,
  type = 'income',
  tax = 'excluded',
) => {
  if (type === 'income') {
    if (tax === 'excluded') return formatCellData(cellData.income.exclude);
    return formatCellData(cellData.income.include);
  }
  if (type === 'averageTrade') {
    if (tax === 'excluded')
      return formatCellData(cellData.averageTrade.exclude);
    return formatCellData(cellData.averageTrade.include);
  }
  return formatCellData(cellData.averageTransaction);
};
