import React from 'react';

const LogOut = ({ color }) => {
  return (
    <svg width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill={color}
        d='M7.039 9.5c0-.157.06-.307.169-.418a.57.57 0 01.407-.173h5.193V5.07C12.808 3.885 11.589 3 10.5 3H5.02c-.536 0-1.05.219-1.428.606A2.096 2.096 0 003 5.068v8.864c0 .548.213 1.074.592 1.462.379.387.892.605 1.427.606h5.77c.535 0 1.048-.219 1.427-.606.378-.388.591-.914.592-1.462V10.09H7.615a.57.57 0 01-.407-.173.598.598 0 01-.17-.418zm12.792-.418l-2.884-2.954a.57.57 0 00-.806.01.598.598 0 00-.01.825l1.9 1.946h-5.223v1.182h5.222l-1.9 1.946a.594.594 0 00-.179.42.606.606 0 00.17.426.574.574 0 00.415.173.565.565 0 00.41-.184l2.885-2.954a.598.598 0 000-.836z'
      />
    </svg>
  );
};

export default LogOut;
