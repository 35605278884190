import { Popover } from 'antd';
import React from 'react';
import DateSelector from '.';
import { TableContext } from '../Table/TableContext';
import { PlusCircleOutlined } from '@ant-design/icons';

function DateSelectorButton({ createAt, column }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { tableUID } = React.useContext(TableContext);

  return (
    <Popover
      content={
        <DateSelector
          createAt={createAt}
          tableUID={tableUID}
          column={column}
          close={() => setIsOpen(false)}
        />
      }
      placement='bottom'
      trigger={'click'}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {column ? (
        <span>
          {formatDate(column.from)} - {formatDate(column.to)}
        </span>
      ) : (
        <PlusCircleOutlined />
      )}
    </Popover>
  );
}

export default DateSelectorButton;

const formatDate = (date) => {
  const arr = date.split('-').reverse();
  arr[arr.length - 1] = arr.at(-1).slice(2, 4);
  return arr.join('.');
};
