import React from 'react';
import { Colors } from '../../utils/colors';

const Income = ({ selected, color }) => {
  return (
    <svg width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={selected ? Colors.white : color}
        strokeMiterlimit='10'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M14 8c0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6 0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6z'
      />
      <path
        stroke={selected ? Colors.white : color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M8 5.5v5M10.5 8h-5'
      />
    </svg>
  );
};

export default Income;
