import { ActionTypes } from './actionsTypes';

export const getTotalIncome = (payload) => ({
  type: ActionTypes.GET_TOTAL_INCOME,
  payload,
});
export const getTopMallIncome = (payload) => ({
  type: ActionTypes.GET_TOP_MALL_INCOME,
  payload,
});
export const getTopStoreIncome = (payload) => ({
  type: ActionTypes.GET_TOP_STORE_INCOME,
  payload,
});

export const getConsolidatedIncome = (payload) => ({
  type: ActionTypes.GET_CONSOLIDATED_INCOME,
  payload,
});
export const getTopPercentageGrowth = (payload) => ({
  type: ActionTypes.GET_TOP_PERCENTAGE_GROWTH,
  payload,
});
export const getConsolidatedFood = (payload) => ({
  type: ActionTypes.GET_CONSOLIDATED_FOOD,
  payload,
});
