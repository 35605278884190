import React, { useCallback, useEffect, useState } from 'react';
import Pen from '../../../../assets/icons/Pen';
import './styles.scss';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import addSpaces from '../../../../utils/addSpaces';
import axiosInstance from '../../../../services/Axios/axiosInstance';
import useOutsideClick from '../../../../utils/hooks/useOutsideClick';

const transformDate = (date) => {
  if (date) return dayjs(date).format('D');
};

const errorStyle = {
  borderWidth: '1px',
  borderColor: 'red',
};

function IncomeCalendarCell({ item, storeInfo, onIncomeAdded }) {
  const [isActive, setIsActive] = useState(false);
  const isFixed = storeInfo?.tax === 'Fixed';

  const [values, setValues] = useState({
    included: {
      error: false,
      value: '',
    },
    excluded: {
      error: false,
      value: '',
    },
  });

  useEffect(() => {
    setValues({
      included: {
        error: false,
        value: item.id ? item.included : '',
      },
      excluded: {
        error: false,
        value: item.id ? item.excluded : '',
      },
    });
  }, [item]);

  const addIncome = (payload) => {
    const body = {
      storeId: payload.storeId,
      included: payload.included,
      excluded: payload.excluded,
      date: dayjs(payload.date).format('YYYY-MM-DD'),
    };
    const putBody = {
      included: payload.included,
      excluded: payload.excluded,
    };
    return payload.itemId
      ? axiosInstance.put(`/incomes/${payload.itemId}`, putBody)
      : axiosInstance.post(`/incomes`, body);
  };

  const changeHandler = async (values) => {
    const valuesAreZeros =
      +values.included.value === 0 && +values.excluded.value === 0;

    if (+values.included.value < +values.excluded.value && !valuesAreZeros) {
      setValues((p) => ({
        included: {
          error: true,
          value: values.included.value,
        },
        excluded: {
          error: true,
          value: p.excluded.value,
        },
      }));
    } else {
      const res = await addIncome({
        storeId: storeInfo?.id,
        included: values.included.value === '' ? null : +values.included.value,
        excluded: values.excluded.value === '' ? null : +values.excluded.value,
        date: item?.date,
        itemId: item?.id,
      });
      onIncomeAdded(
        res?.id
          ? res
          : {
              storeId: storeInfo?.id,
              included:
                values.included.value === '' ? null : +values.included.value,
              excluded:
                values.excluded.value === '' ? null : +values.excluded.value,
              date: item?.date,
              id: item?.id,
            },
      );
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), [
    item?.id,
    item?.date,
    item?.storeId,
  ]);

  const onIncludedChange = (event) => {
    const value = event.target.value.replaceAll(' ', '').replace(/[^\d.]/g, '');

    const newValues = {
      included: { error: false, value },
      excluded: isFixed
        ? { error: false, value: value === '' ? '' : value / 1.25 }
        : values.excluded,
    };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const onExcludedChange = (event) => {
    if (isFixed) return;
    const value = event.target.value.replaceAll(' ', '').replace(/[^\d.]/g, '');
    const newValues = {
      ...values,
      excluded: { error: false, value },
    };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const isAfterToday = dayjs(item.date).isAfter(dayjs());

  const isActivated = isActive && !isAfterToday;

  const ref = useOutsideClick((e) => {
    if (isActive) setIsActive(false);
  });

  const isSunday = dayjs(item?.date).day() === 0;

  const isDisabled =
      !isSunday && (
          (storeInfo?.endDate
              ? dayjs(storeInfo.endDate).add(1, 'day').isBefore(dayjs(item?.date))
              : false) ||
          (storeInfo?.startDate
              ? dayjs(storeInfo.startDate).isAfter(dayjs(item?.date))
              : false) ||
          item.isLocked
      );

  const excluded = React.useRef();
  const included = React.useRef();

  const activate = (value) => {
    if (!isDisabled) {
      setIsActive(value);
      if (typeof value === 'string') {
        if (value === 'excluded')
          setTimeout(() => excluded.current.focus(), 10);
        if (value === 'included')
          setTimeout(() => included.current.focus(), 10);
      }
    }
  };

  return (
    <div
      className='IncomeCalendarCell'
      style={
        isActivated
          ? { gap: 7 }
          : { gap: 12, opacity: isAfterToday || isDisabled ? 0.6 : 1 }
      }
      ref={ref}
    >
      <div className='IncomeCalendarCell__day'>{transformDate(item?.date)}</div>

      <div
        className='editableCalendarInput excluded'
        style={{ display: !isActivated ? 'none' : 'flex' }}
      >
        <Pen />
        <input
          ref={excluded}
          value={addSpaces(values.excluded.value, { emptyValue: '' })}
          onChange={onExcludedChange}
          disabled={isFixed ||isDisabled}
          style={values.excluded.error ? errorStyle : {}}
        />
      </div>
      <div
        className='editableCalendarInput'
        style={{ display: !isActivated ? 'none' : 'flex' }}
      >
        <Pen />
        <input
          disabled={isDisabled}
          ref={included}
          value={addSpaces(values.included.value, { emptyValue: '' })}
          onChange={onIncludedChange}
          style={values.included.error ? errorStyle : {}}
        />
      </div>

      <text
        className='sum'
        onClick={(e) => {
          activate('excluded');
        }}
        style={{ display: isActivated ? 'none' : 'block' }}
      >
        {item.excluded !== null ? addSpaces(item.excluded) : '-'}
      </text>
      <text
        className='sum included'
        onClick={(e) => {
          activate('included');
        }}
        style={{ display: isActivated ? 'none' : 'block' }}
      >
        {item.included !== null ? addSpaces(item.included) : '-'}
      </text>
    </div>
  );
}

export default IncomeCalendarCell;
