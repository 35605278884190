import MallInfo from '../pages/AdminPanel/pages/mall/MallInfo';
import MallStoresTable from '../pages/AdminPanel/pages/mall/MallStoresTable';
import MallTable from '../pages/AdminPanel/pages/mall/MallTable';
import MallUsersTable from '../pages/AdminPanel/pages/mall/MallUsersTable';
import StoreInfo from '../pages/AdminPanel/pages/store/StoreInfo';
import StoreUsers from '../pages/AdminPanel/pages/store/StoreUsers';
import StoresTable from '../pages/AdminPanel/pages/store/StoresTable';
import UserInfo from '../pages/AdminPanel/pages/user/UserInfo';
import UsersTable from '../pages/AdminPanel/pages/user/UsersTable';
import { Home } from '../pages/Home/Home';
import { Income } from '../pages/Income/Income';
import { Login } from '../pages/Login/Login';
import { ReportingList } from '../pages/ReportingList/ReportingList';

const ROUTES = {
  AUTH: { LOGIN: '/login' },
  ADMIN: {
    MALLS: {
      LIST: '/admin/malls',
      INFO: '/admin/malls/:mallId/info',
      STORES: '/admin/malls/:mallId/stores',
      USERS: '/admin/malls/:mallId/users',
    },
    STORES: {
      LIST: '/admin/stores',
      INFO: '/admin/stores/:storeId/info',
      USERS: '/admin/stores/:storeId/users',
    },
    USERS: {
      LIST: '/admin/users',
      INFO: '/admin/users/:userId/info',
    },
  },
  REPORTS: {
    LIST: '/list',
    INCOME_LIST: '/income',
  },
  DASHBOARD: {
    HOME: '/dashboard',
  },
};

export const getMallInfoLink = (id) =>
  ROUTES.ADMIN.MALLS.INFO.replace(':mallId', id);
export const getMallStoresLink = (id) =>
  ROUTES.ADMIN.MALLS.STORES.replace(':mallId', id);
export const getMallUsersLink = (id) =>
  ROUTES.ADMIN.MALLS.USERS.replace(':mallId', id);

export const getStoreInfoLink = (id) =>
  ROUTES.ADMIN.STORES.INFO.replace(':storeId', id);
export const getStoreUsersLink = (id) =>
  ROUTES.ADMIN.STORES.USERS.replace(':storeId', id);

export const getUserInfoLink = (id) =>
  ROUTES.ADMIN.USERS.INFO.replace(':userId', id);

const admin = [
  {
    path: ROUTES.ADMIN.MALLS.LIST,
    Component: MallTable,
  },
  {
    path: ROUTES.ADMIN.MALLS.INFO,
    Component: MallInfo,
  },
  {
    path: ROUTES.ADMIN.MALLS.STORES,
    Component: MallStoresTable,
  },
  {
    path: ROUTES.ADMIN.MALLS.USERS,
    Component: MallUsersTable,
  },
  {
    path: ROUTES.ADMIN.STORES.LIST,
    Component: StoresTable,
  },
  {
    path: ROUTES.ADMIN.STORES.INFO,
    Component: StoreInfo,
  },
  {
    path: ROUTES.ADMIN.STORES.USERS,
    Component: StoreUsers,
  },
  {
    path: ROUTES.ADMIN.USERS.LIST,
    Component: UsersTable,
  },
  {
    path: ROUTES.ADMIN.USERS.INFO,
    Component: UserInfo,
  },
];
const dashboard = [{ path: ROUTES.DASHBOARD.HOME, Component: Home }];
const reports = [
  { path: ROUTES.REPORTS.LIST, Component: ReportingList },
  { path: ROUTES.REPORTS.INCOME_LIST, Component: Income },
];
const auth = [{ path: ROUTES.AUTH.LOGIN, Component: Login }];

export default ROUTES;
export { dashboard, auth, reports, admin };
