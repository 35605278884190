import React from 'react';
import { LoadingButton } from '@mui/lab';
import './styles.scss';

function GrayButton(props) {
  return (
    <LoadingButton {...props} className={`GrayButton ${props.className}`}>
      {!props.loading && (props.text || props.children)}
    </LoadingButton>
  );
}

export default GrayButton;
