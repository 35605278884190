import { Button, Modal } from '@mui/material';
import React from 'react';
import AdminFormInput, {
  AdminFormPasswordInput,
  AdminFormPhoneInput,
} from '../../AdminFormInput';
import AdminFormSelect from '../../../components/AdminFormSelect';
import '../sharedStyles.scss';
import {
  ROLES,
  getTranslatedRoles,
  roles,
} from '../../../utils/formatForTables';
import useAddUser from './useAddUser';
import GrayButton from '../../../../../components/GrayButton';

function AddUser({ isOpen, close, mall, store, onUserCreated }) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    t,
    malls,
    stores,
    handleBlur,
    setFieldTouched,
  } = useAddUser({ onUserCreated, store, mall, close });

  return (
    <Modal open={isOpen} onClose={close} onBackdropClick={close}>
      <div className='adminModal'>
        <div>
          <h5>{store?.name}</h5>
          <h3>{t('add_New_User')}</h3>
          <div>
            <AdminFormInput
              name={`${t('adminTable.name')}*`}
              placeholder={t('adminTable.name')}
              value={values.name}
              error={touched.name && errors.name}
              onBlur={handleBlur('name')}
              onChange={(val) => setFieldValue('name', val)}
            />
            <AdminFormInput
              name={`${t('adminTable.surname')}*`}
              placeholder={t('adminTable.surname')}
              value={values.surname}
              error={touched.surname && errors.surname}
              onChange={(val) => setFieldValue('surname', val)}
              onBlur={handleBlur('surname')}
            />
            <AdminFormPhoneInput
              name={`${t('adminTable.phoneNumber')}*`}
              placeholder={t('adminTable.phoneNumber')}
              value={values.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
              onChange={(val) => setFieldValue('phoneNumber', val)}
              onBlur={handleBlur('phoneNumber')}
            />
            <AdminFormInput
              name={`${t('adminTable.email')}*`}
              placeholder={t('adminTable.email')}
              value={values.email}
              error={touched.email && errors.email}
              onBlur={handleBlur('email')}
              onChange={(val) => setFieldValue('email', val)}
            />
            <AdminFormPasswordInput
              name={`${t('adminTable.password')}*`}
              placeholder={t('adminTable.password')}
              value={values.password}
              onChange={(val) => setFieldValue('password', val)}
              error={touched.password && errors.password}
              onBlur={handleBlur('password')}
            />
            {!store && (
              <>
                <AdminFormSelect
                  name={`${t('adminTable.userRole')}*`}
                  placeholder={t('adminTable.userRole')}
                  value={values.role}
                  options={Object.keys(roles).map((key) => ({
                    label: getTranslatedRoles()[key],
                    value: key,
                  }))}
                  onChange={(val) => setFieldValue('role', val)}
                  error={touched.role && errors.role}
                  onBlur={() =>
                    setTimeout(() => setFieldTouched('role', true), 100)
                  }
                />
                <AdminFormSelect
                  name={t('adminTable.mall')}
                  placeholder={t('adminTable.mall')}
                  value={values.mall}
                  options={malls.map((mall) => ({
                    label: mall.name,
                    value: mall.id,
                  }))}
                  onChange={(val) => setFieldValue('mall', val)}
                  onBlur={() =>
                    setTimeout(() => setFieldTouched('mall', true), 100)
                  }
                />
                <AdminFormSelect
                  name={t('adminTable.store')}
                  placeholder={t('adminTable.store')}
                  value={values.store}
                  options={stores.map((store) => ({
                    label: store.name,
                    value: store.id,
                  }))}
                  disabled={values.role !== ROLES.STORE_MANAGER}
                  onChange={(val) => setFieldValue('store', val)}
                  onBlur={() =>
                    setTimeout(() => setFieldTouched('store', true), 100)
                  }
                />
              </>
            )}
          </div>
          <div className='adminModal__buttons'>
            <GrayButton type='button' onClick={close}>
              {t('cancel')}
            </GrayButton>
            <GrayButton type='button' onClick={handleSubmit}>
              {t('save')}
            </GrayButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUser;
