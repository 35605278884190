import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HideDown from '../../assets/icons/HideDown';
import { Header } from '../../components/Header/Header';
import { LeftSideMenu } from '../../components/LeftMenu/LeftMenu';
import { setVisibleCharts } from '../../services/Redux/leftMenuReducer/action';
import { Colors } from '../../utils/colors';
import './style.scss';
import HomePageCards from '../../components/HomePageCards';
import IncomeTable from '../../components/IncomeTable';

export const Home = () => {
  const { t } = useTranslation();
  const visibleCharts = useSelector(
    (state) => state.leftMenuReducer.visibleCharts,
  );
  const [color, setColor] = useState('#FAFAFA');
  const dispatch = useDispatch();
  const [incomeTables, setIncomeTables] = useState([0]);

  return (
    <>
      <Header />
      <div
        className='mainContainer'
        style={{
          backgroundColor: Colors.mainBlack,
        }}
      >
        <div
          style={{
            backgroundColor: Colors.mainBlack,
          }}
          className='homeMainContainer'
        >
          <LeftSideMenu selected='HOME' />
          <div
            className='homeContainer'
            style={{
              backgroundColor: Colors.mainBlack,
              paddingTop: visibleCharts ? '24px' : 0,
            }}
          >
            {visibleCharts ? (
              <HomePageCards />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className='chartsContainer'
                  onMouseEnter={() => setColor('rgba(218, 253, 0, 1)')}
                  onMouseLeave={() => setColor('#FAFAFA')}
                >
                  <text className='chartsTitle'>{t('charts')}</text>
                  <button
                    className='chartsButton'
                    onClick={() => dispatch(setVisibleCharts(true))}
                  >
                    <HideDown color={color} />
                  </button>
                </div>
                <div style={{ marginTop: '10px', marginRight: '12px' }}>
                  <button
                    className='addTable'
                    onClick={() =>
                      setIncomeTables([...incomeTables, incomeTables.length])
                    }
                  >
                    <text className='addTableText'>+ {t('add_table')}</text>
                  </button>
                </div>
              </div>
            )}
            <IncomeTable />
          </div>
        </div>
      </div>
    </>
  );
};
