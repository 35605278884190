import { cloneDeep } from 'lodash';
import { calculateMoneyDiff, calculatePercentageDiff } from './getDiff';

const clone = (x) => JSON.parse(JSON.stringify(x));

const fillTable = (tablesSettings, totalIncomeAmount) => {
  const copieTotalIncomeAmount = clone(totalIncomeAmount);
  const copiedTablesSettings = clone(tablesSettings);

  Object.keys(copiedTablesSettings).map((tableUID) => {
    if (!copieTotalIncomeAmount[tableUID]) return;
    copiedTablesSettings[tableUID] = {
      ...copiedTablesSettings[tableUID],
      malls:
        copiedTablesSettings[tableUID].columns
          .filter((col) => col.isVisible)
          .filter((col) => !col.isDiff).length === 2
          ? copieTotalIncomeAmount[tableUID].map((mall) => {
              return {
                ...mall,
                stores: mall.stores.map((store) => ({
                  ...store,
                  numberDiff: calculateMoneyDiff(store),
                  percentageDiff: calculatePercentageDiff(store),
                })),
              };
            })
          : copieTotalIncomeAmount[tableUID],
    };
  });

  return copiedTablesSettings;
};

export default fillTable;
