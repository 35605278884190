import React, { createContext, useContext } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import { getShouldDisplayDiff } from '../../utils/getDiff';

export const TableContext = createContext({});

export const TableProvider = ({ tableUID, children }) => {
  const { tablesSettings, selectedTable, isEditing } =
    useContext(IncomeTableContext);
  const tableSettings = tablesSettings[tableUID];

  const { columns } = tableSettings;

  const isEdit = isEditing && tableUID === selectedTable;

  const shouldDisplayDiff = getShouldDisplayDiff(columns);

  const shownColumns = columns.filter((x) =>
    shouldDisplayDiff
      ? isEdit
        ? true
        : x.isVisible
      : x.isVisible && !x.isDiff,
  );

  const numbersDiffColumn = columns.find((col) => col.diffType === 'numbers');
  const percentageDiffColumn = columns.find(
    (col) => col.diffType === 'percentage',
  );

  const shouldDisplayAdd =
    isEdit && columns.filter((x) => !x.isDiff).length < 6;

  return (
    <TableContext.Provider
      value={{
        tableUID,
        malls: tableSettings.sortedMalls || [],
        tableSettings,
        isEdit,
        shownColumns,
        numbersDiffColumn,
        percentageDiffColumn,
        shouldDisplayAdd,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
