import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminApi from '../../../../../services/Axios/AdminApi';
import * as Yup from 'yup';
import { ROLES } from '../../../utils/formatForTables';
import showNotification from '../../../../../utils/showNotification';
import dayjs from 'dayjs';

function useAddStore({ onStoreCreated, mall, close }) {
  const { t, i18n } = useTranslation();
  const taxes = [
    { value: 'Reduced', label: t('reduced') },
    { value: 'Fixed', label: t('fixed') },
  ];
  const [categories, setCategories] = React.useState([]);
  const [mallManagers, setMallManagers] = React.useState([]);
  const [malls, setMalls] = React.useState([]);

  const validationSchema = Yup.object().shape({
    storeName: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(35, t('validation.tooLong'))
      .trim(t('validation.trimError'))
      .matches(/^[a-zA-ZæøåÆØÅ0-9 ]+$/, t('validation.shouldBeText'))
      .required(t('validation.required')),
    category: Yup.string().required(t('validation.required')),
    tax: Yup.string().required(t('validation.required')),
    mall: Yup.string().required(t('validation.required')),
  });

  React.useEffect(() => {
    AdminApi.getCategories().then((res) => {
      setCategories(res);
    });
    AdminApi.getUsers({
      role: ROLES.STORE_MANAGER,
      limit: 149,
      available: true,
    }).then((res) => {
      setMallManagers(res.data);
    });
    AdminApi.getMalls().then((res) => {
      setMalls(res.data);
    });
  }, []);

  const initialValues = {
    storeName: '',
    category: null,
    tax: null,
    storeManager: null,
    mall: null,
    startDate: dayjs(),
    endDate: null,
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    if (values.startDate && values.endDate) {
      if (dayjs(values.endDate).isBefore(values.startDate)) {
        showNotification({
          content: "End date can't be before start date !",
          type: 'error',
        });
        setSubmitting(false);
        return;
      }
    }
    AdminApi.createStore({
      name: values.storeName,
      tax: values.tax,
      storeGroupId: values.category,
      mallId: values?.mall ?? mall?.id,
      storeManagerId: values.storeManager ?? undefined,
      startDate: values.startDate
        ? values.startDate.format('YYYY-MM-DD')
        : null,
      endDate: values.endDate ? values.endDate.format('YYYY-MM-DD') : null,
    })
      .then((res) => {
        onStoreCreated(res);
        close();
        resetForm();
        setMallFormValue();
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
  };

  const { values, setFieldValue, handleSubmit, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  const setMallFormValue = () => {
    if (mall?.id) setFieldValue('mall', mall.id);
    else setFieldValue('mall', null);
  };

  React.useEffect(() => {
    setMallFormValue();
  }, [mall]);

  return {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    t,
    malls,
    touched,
    handleBlur,
    categories,
    mallManagers,
    i18n,
    taxes,
  };
}

export default useAddStore;
