import React from 'react';

const LanguageIcon = ({ color = '#797979', width, height }) => {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      fill='none'
      viewBox='0 0 12 12'
    >
      <g stroke={color} strokeWidth='0.7' clipPath='url(#clip0_2004_5797)'>
        <path
          strokeMiterlimit='10'
          d='M6 1.125a4.875 4.875 0 100 9.75 4.875 4.875 0 000-9.75z'
        ></path>
        <path
          strokeMiterlimit='10'
          d='M6 1.125C4.64 1.125 3.36 3.308 3.36 6S4.64 10.875 6 10.875c1.361 0 2.64-2.183 2.64-4.875S7.362 1.125 6 1.125z'
        ></path>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M2.75 2.75A5.606 5.606 0 006 3.766c1.226 0 2.354-.38 3.25-1.016m0 6.5A5.606 5.606 0 006 8.234c-1.225 0-2.354.38-3.25 1.016'
        ></path>
        <path strokeMiterlimit='10' d='M6 1.125v9.75M10.875 6h-9.75'></path>
      </g>
      <defs>
        <clipPath id='clip0_2004_5797'>
          <path fill='#fff' d='M0 0H12V12H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LanguageIcon;
