import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  logOutRed,
  setError,
  setIsLoading,
  setUser,
} from '../../Redux/loginReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';
import ROUTES from '../../../routes/route';
import showNotification from '../../../utils/showNotification';
import { getI18n } from 'react-i18next';

export function* watcherLogin() {
  yield takeEvery(ActionTypes.LOGIN, workerLogin);
}
export function* watcherLogOut() {
  yield takeEvery(ActionTypes.LOG_OUT, workerLogOut);
}
export function* watcherGetUserInfo() {
  yield takeEvery(ActionTypes.GET_USER_INFO, workerGetUserInfo);
}
export function* watcherSetUserLanguage() {
  yield takeEvery(ActionTypes.SET_USER_LANGUAGE, workerSetUserLanguage);
}
export function* workerLogin({ payload }) {
  const body = {
    email: payload.email.trim(),
    password: payload.password.trim(),
  };
  try {
    const res = yield call(axiosInstance.post, `/auth/sing-in`, body);
    if (res.accessToken) {
      yield put(setUser(res));
      payload.navigate(ROUTES.DASHBOARD.HOME);
      const x = yield call(axiosInstance.get, `/users/info`);
      yield put(setUser({ user: x }));
    }
  } catch (e) {
    if (e?.response?.status == 400)
      yield showNotification({
        type: 'error',
        content: getI18n().t('email_password'),
      });
  }
  yield true;
}

export function* workerLogOut({ payload }) {
  yield put(logOutRed());
  payload('/login');
}

export function* workerGetUserInfo() {
  yield put(setIsLoading(true));
  const token = yield select((state) => state?.loginReducer?.accessToken);
  if (token) {
    const res = yield call(axiosInstance.get, `/users/info`);
    yield put(setUser({ user: res }));
  }
  yield put(setIsLoading(false));
}

export function* workerSetUserLanguage({ payload }) {
  const res = yield call(axiosInstance.post, `/users/lang`, { lang: payload });
}
