import React, { useContext } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import './styles.scss';
import Sort from '../../../../assets/icons/Sort';
import { Popover } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ToggleableEye from '../ToggleableEye';
import { getShouldDisplayDiff } from '../../utils/getDiff';
import DateSelector from '../DateSelector';
import { TableContext } from '../Table/TableContext';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import HeaderColumnItem from './HeaderColumnItem';
import Select from './Select';
import DateSelectorButton from '../DateSelector/DateSelectorButton';

function TableHeader() {
  const [activeColumnUid, setActiveColumnUid] = React.useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );
  const { changeColumnSort, toggleSort, toggleColumnVisibility } =
    useContext(IncomeTableContext);
  const {
    tableUID,
    tableSettings,
    isEdit,
    numbersDiffColumn,
    percentageDiffColumn,
    shouldDisplayAdd,
  } = useContext(TableContext);

  const { columns } = tableSettings;

  const shouldDisplayDiff = getShouldDisplayDiff(columns);

  const shouldShowNumberDiff =
    shouldDisplayDiff && (isEdit || numbersDiffColumn.isVisible);
  const shouldShowPercentageDiff =
    shouldDisplayDiff && (isEdit || percentageDiffColumn.isVisible);

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveColumnUid(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = columns.findIndex((x) => x.columnUID === active.id);
      const newIndex = columns.findIndex((x) => x.columnUID === over.id);

      changeColumnSort(tableUID, arrayMove(columns, oldIndex, newIndex));
    }
    setActiveColumnUid(null);
  };

  if (!columns) return <></>;
  return (
    <div className='TableHeader'>
      <div>
        <div style={{ minWidth: 160 }} />
        {shouldDisplayAdd && (
          <div className='TableHeader__column add'>
            <DateSelectorButton createAt={'start'} />
          </div>
        )}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={columns} strategy={rectSortingStrategy}>
            {columns
              .filter((col) => !col.isDiff)
              .map((col) => (
                <HeaderColumnItem column={col} />
              ))}
          </SortableContext>
          <DragOverlay>
            {activeColumnUid ? (
              <HeaderColumnItem
                column={columns.find((x) => x.columnUID === activeColumnUid)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        {shouldShowNumberDiff && (
          <div className='TableHeader__column'>
            {isEdit && (
              <ToggleableEye
                isDisabled={!numbersDiffColumn.isVisible}
                onClick={() =>
                  toggleColumnVisibility(tableUID, numbersDiffColumn.columnUID)
                }
              />
            )}
            <div
              className='TableHeader__column__name'
              style={{ opacity: !numbersDiffColumn.isVisible ? 0.6 : 1 }}
            >
              <div
                className='TableHeader__column__sort'
                onClick={() =>
                  toggleSort(tableUID, numbersDiffColumn?.columnUID)
                }
              >
                <Sort />
              </div>
              <span>Diff</span>
            </div>
          </div>
        )}
        {shouldShowPercentageDiff && (
          <div className='TableHeader__column'>
            {isEdit && (
              <ToggleableEye
                isDisabled={!percentageDiffColumn.isVisible}
                onClick={() =>
                  toggleColumnVisibility(
                    tableUID,
                    percentageDiffColumn.columnUID,
                  )
                }
              />
            )}
            <div
              className='TableHeader__column__name'
              style={{ opacity: !percentageDiffColumn.isVisible ? 0.6 : 1 }}
            >
              <div
                className='TableHeader__column__sort'
                onClick={() =>
                  toggleSort(tableUID, percentageDiffColumn?.columnUID)
                }
              >
                <Sort />
              </div>
              <span>Diff %</span>
            </div>
          </div>
        )}
        {shouldDisplayAdd && (
          <div className='TableHeader__column add'>
            <DateSelectorButton createAt={'end'} />
          </div>
        )}
        <div className='TableHeader__column' />
        <Select />
      </div>
    </div>
  );
}

export default TableHeader;
