import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Header } from '../../components/Header/Header';
import { LeftSideMenu } from '../../components/LeftMenu/LeftMenu';
import { Colors } from '../../utils/colors';
import { Select } from 'antd';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getReportingList } from '../../services/Saga/getReportingList/actions';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import MonthsComponent from '../../components/MonthsComponent';
import YearPicker from '../../components/YearPicker';
import Triangle from '../../assets/icons/Triangle';
import { getAllMalls } from '../../services/Saga/filters/actions';
import ROUTES from '../../routes/route';
import {
  setFilters,
  setReportingList,
} from '../../services/Redux/reportingListReducer/action';

export const ReportingList = () => {
  const { t } = useTranslation();
  const reportingList = useSelector(
    (state) => state.reportingListReducer.reportingList,
  );
  const reportingListTotal = useSelector(
    (state) => state.reportingListReducer.total,
  );
  const allMalls = useSelector((state) => state.filtersReducer.allMalls);
  const filters = useSelector((state) => state.reportingListReducer.filters);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  const setMallId = (selectedMallId) => {
    dispatch(setFilters({ selectedMallId }));
  };
  const setSelectedMonth = (selectedMonth) => {
    dispatch(setFilters({ selectedMonth }));
  };
  const setDate = (selectedDates) => {
    dispatch(setFilters({ selectedDates }));
  };
  const setOrder = (selectedOrder) => {
    dispatch(setFilters({ selectedOrder }));
  };

  const {
    selectedMonth,
    selectedMallId: mallId,
    selectedDates: date,
    selectedOrder: order,
  } = filters;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const sortingOptions = [
    {
      label: <text className='listSelectText'>{t('all')}</text>,
      value: '',
    },
    {
      label: <text className='listSelectText'>{t('reported')}</text>,
      value: 'REPORTED',
    },
    {
      label: <text className='listSelectText'>{t('not_Reported')}</text>,
      value: 'NOT_REPORTED',
    },
  ];

  useEffect(() => {
    const get = async () => {
      if (!mallId) return;
      setIsLoading(true);
      await dispatch(
        getReportingList({
          order,
          date,
          mallId,
          page,
        }),
      );
    };

    get();
  }, [date, mallId, page, order]);

  useEffect(() => {
    dispatch(
      getAllMalls({
        order: 'ASC',
      }),
    );
  }, []);

  useEffect(() => {
    if (allMalls?.data?.length > 0)
      setMallId(
        allMalls.data.find((mall) => mall.id === mallId)?.id ||
          allMalls.data[0].id,
      );
  }, [allMalls]);

  useEffect(() => {
    setHasMore(reportingListTotal > reportingList?.length);
    setIsLoading(false);
  }, [reportingList]);

  const observer = useRef();
  const lastVisible = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((p) => p + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  const onClickYear = (year) => {
    dispatch(
      setReportingList({
        data: [],
        total: null,
      }),
    );
    const start = dayjs(`${year}-${selectedMonth.number}-01`).startOf('month');
    const end = dayjs(`${year}-${selectedMonth.number}-01`).endOf('month');
    setDate([start, end]);
  };

  const onMonthChange = (month) => {
    setSelectedMonth(month);
    dispatch(
      setReportingList({
        reportingList: [],
        total: null,
      }),
    );
    const start = dayjs(`${date?.[0]?.$y}-${month.number}-01`).startOf('month');
    const end = dayjs(`${date?.[0]?.$y}-${month.number}-01`).endOf('month');
    setDate([start, end]);
  };

  const mallsOptions = (allMalls.data || []).map((mall) => ({
    value: mall.id,
    label: mall.name,
  }));

  return (
    <>
      <Header />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <LeftSideMenu selected='LIST' />
        <div
          className='mallContainer'
          style={{
            backgroundColor: Colors.mainBlack,
          }}
        >
          <div className='listSortingContainer'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <Select
                  className='listSortingButton'
                  style={{ width: 148 }}
                  dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)' }}
                  bordered={false}
                  suffixIcon={<Triangle />}
                  value={
                    mallsOptions.find((mall) => mall.value === mallId) ||
                    mallsOptions?.[0]?.value
                  }
                  options={mallsOptions}
                  onChange={setMallId}
                />
                <YearPicker value={date?.[0]?.$y} onChange={onClickYear} />{' '}
                <Select
                  className='listSortingButton'
                  style={{ minWidth: 72 }}
                  dropdownStyle={{
                    backgroundColor: 'rgb(18, 18, 18)',
                    minWidth: 116,
                  }}
                  bordered={false}
                  suffixIcon={<Triangle />}
                  value={
                    sortingOptions.find((opt) => opt.value === order) ||
                    sortingOptions[0].value
                  }
                  options={sortingOptions}
                  onChange={setOrder}
                />
              </div>
              <MonthsComponent
                selectedMonth={selectedMonth}
                setSelectedMonth={onMonthChange}
                selectedYear={date[0].$y}
              />
            </div>
          </div>{' '}
          <div className='listWrapper'>
            <div className='listButtonsContainer'>
              {reportingList?.map((item, index) => (
                <button
                  className={
                    item.isReported ? 'listButtonGreen' : 'listButtonRed'
                  }
                  key={index}
                  ref={
                    index === reportingList?.length - 1
                      ? lastVisible
                      : undefined
                  }
                  onClick={() =>
                    navigate(ROUTES.REPORTS.INCOME_LIST, {
                      state: {
                        item: item,
                        selected: 'LIST',
                        date,
                      },
                    })
                  }
                >
                  <text
                    className='listButtonText'
                    style={{
                      color: item.isReported
                        ? Colors.listButtonTextGreen
                        : Colors.listButtonTextRed,
                    }}
                  >
                    {item.name}
                  </text>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
